import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IUser } from '../../@model/user.model';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  isLogin = false;
  roleAs: string;
  user: IUser;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {
    this.roleAs = 'USER';
  }

  login(user: IUser, value: string) {
    this.user = user;
    const url = environment.endPoint + '/api/auth/login';
    const params = { identification: user.identification, password: user.password };

    this.isLogin = true;
    this.roleAs = value;

    return this.http.post(url, params);
  }

  getUser() {
    const url = environment.endPoint + '/api/auth/user';
    return this.http.get(url);
  }

  update(user: IUser) {
    const url = environment.endPoint + '/api/acl/updateuser';
    const params = {
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      phone: user.phone,
      direction: user.direction,
    };

    return this.http.put(url, params);
  }

  register(user: IUser) {
    const url = environment.endPoint + '/api/auth/signup';
    const params = {
      name: user.name,
      lastname: user.lastname,
      identification: user.identification,
      email: user.email,
      phone: user.phone,
      password: user.password,
      direction: user.direction,
      person_id: user.person.toString(),
      economic_id: user.activity.toString(),
    };

    return this.http.post(url, params);
  }

  logout() {
    this.isLogin = false;
    this.roleAs = '';
    const url = environment.endPoint + '/api/auth/logout';

    return this.http.get(url);
  }

  resendMailVerification() {
    const url = environment.endPoint + '/api/auth/email/resend';

    return this.http.get(url);
  }

  resetPassword(email, token, password) {
    const url = environment.endPoint + '/api/password/reset';
    const params = { email, token, password, password_confirmation: password };

    return this.http.post(url, params);
  }

  isAuthenticated() {
    this.user = this.storageService.getItem('user');
    this.isLogin = this.user ? this.user.token ? true : false : false;

    return this.isLogin;
  }

  getRole() {
    return this.roleAs;
  }
}
